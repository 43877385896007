import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useWatch } from 'react-hook-form';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import omit from 'lodash/omit';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';
import toNumber from 'lodash/toNumber';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { AvBillingInfoNotes } from '../../../../../../avBillingInfos/avBillingInfosTypes';
import { BillingInfoID } from '../../../../../../billingInfos/billingInfosTypes';
import { Currencies } from '../../../../../../../types';
import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormDataTaskItem,
  CreateProjectInTeamFormDataTaskItems,
  CreateProjectInTeamFormDataTasks,
  CreateProjectInTeamFormFields
} from '../../CreateProjectInTeamForm.types';
import { ProjectNanoID, ProjectTeamNanoID } from '../../../../../projectsTypes';
import {
  TeamPreferredPaymentMethod,
  TeamTerms
} from '../../../../../../teams/teamsTypes';
import { InvoicePaymentMethods } from '../../../../../../invoices/invoicesTypes';
import { UserID } from '../../../../../../users/usersTypes';
import { GeneralLedgerGeneralLedgerTypes } from '../../../../../../generalLedgers/generalLedgersTypes';
import { CreateProjectInTeamError } from '../../../../../hooks/useCreateProjectInTeam/useCreateProjectInTeam';
import { CreateTaskItemsFormFields } from '../../../../../../tasks/components/forms/CreateTaskItemsForm/CreateTaskItemsForm.types';
import { SmartContractShareStatuses } from '../../../../../../smartContractShares/smartContractSharesTypes';

import {
  FETCH_GENERAL_LEDGERS_QUERY,
  FetchGeneralLedgersQueryResponse
} from '../../../../../../generalLedgers/queries/fetchGeneralLedgers.query';

import { useConvertCurrencyRates } from '../../../../../../currencyRates/hooks/useConvertCurrencyRates';
import { useCreateProjectInTeam } from '../../../../../hooks/useCreateProjectInTeam';
import { useCreateProjectInTeamValidationRules } from '../useCreateProjectInTeamValidationRules';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useFinPaginatedGeneralLedgers } from '../../../../../../generalLedgers/hooks/useFinPaginatedGeneralLedgers';
import { useFormattedCurrencyRates } from '../../../../../../currencyRates/hooks/useFormattedCurrencyRates';
import { useLocalStorageProxyState } from '../../../../../../../common/hooks/useLocalStorageProxyState';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { CREATE_PROJECT_IN_TEAM_QUERY } from '../../../../../queries/createProjectInTeam.query';

import { MultiSelectFieldChangeCallbackType } from '../../../../../../../helpers/FormFields/MultiSelectField';
import { MultiSelectDataType } from '../../../../../../../helpers/MultiSelect/types';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';
import { generateNanoId } from '../../../../../../../utils/generateNanoId';
import { generateTinyNanoId } from '../../../../../../../utils/generateTinyNanoId';
import { getDueDate } from '../../../../../../../utils/getDueDate';

import { ProjectCache } from '../../../../../ProjectCache';
import { GeneralLedgerCache } from '../../../../../../generalLedgers/GeneralLedgerCache';
import { ProjectsPermissions } from '../../../../../projectsConstants';
import {
  companyCurrencyLocalStorageKey,
  CompanyCurrencyStateType,
  defaultCompanyCurrencyState
} from '../../../../../../teams/teamsConstants';

import { formsErrors, tasksKeys } from '../../../../../../../locales/keys';

interface CreateProjectInTeamFormOptions {
  afterCreateProject: (projectNanoId: ProjectNanoID) => Promise<boolean>;
  initialItems?: CreateProjectInTeamFormDataTaskItems;
  ownerId?: UserID;
  preferredPaymentMethod: TeamPreferredPaymentMethod;
  teamNanoId: ProjectTeamNanoID;
  teamTerms: TeamTerms;
}

function useCreateProjectInTeamForm({
  afterCreateProject,
  initialItems = [],
  ownerId,
  preferredPaymentMethod,
  teamNanoId,
  teamTerms
}: CreateProjectInTeamFormOptions) {
  const [companyCurrency, setCompanyCurrency] =
    useLocalStorageProxyState<CompanyCurrencyStateType>(
      companyCurrencyLocalStorageKey(teamNanoId),
      defaultCompanyCurrencyState
    );

  const [formNanoId, setFormNanoId] = useState<string>(generateNanoId());

  const [projectNanoId] = useState<ProjectNanoID>(
    generateTinyNanoId() as ProjectNanoID
  );

  const [afterCreateProjectInTeamLoading, setAfterCreateProjectInTeamLoading] =
    useState<boolean>(false);

  const resetFormNanoId = useCallback<() => void>(
    () => setFormNanoId(generateNanoId()),
    []
  );

  const currentUser = useCurrentUser();

  const defaultProjectValues: CreateProjectInTeamFormData = {
    generalLedgerId: null,
    billingInfoId: null,
    description: '',
    fileAttachmentIds: [],
    implementationDate: '',
    name: '',
    notes: '',
    ownerId,
    paymentMethod: preferredPaymentMethod || InvoicePaymentMethods.CARD,
    tasks: currentUser.hasPermissions(
      ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TASKS
    )
      ? [
          {
            name: 'Task 1',
            users: [],
            items: initialItems
          }
        ]
      : undefined,
    teamNanoId,
    terms: teamTerms || '',
    preferredCurrency: companyCurrency.currency,
    splitParts: [{ partValue: '' }]
  };

  const {
    control,
    errors,
    getValues,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue,
    watch,
    trigger,
    setFocus
  } = useReactHookForm<CreateProjectInTeamFormData>({
    defaultValues: {
      ...defaultProjectValues,
      implementationDate: currentUser.hasPermissions(
        ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_TIME
      )
        ? defaultProjectValues.implementationDate
        : dateFnsConvert.toDate(defaultProjectValues.implementationDate)
    }
  });

  const watchCurrency = watch(CreateProjectInTeamFormFields.PREFERRED_CURRENCY);

  const { currencyExchangeRate, currencyPrefix } = useFormattedCurrencyRates({
    currency: watchCurrency
  });

  const { implementationDateRules, nameRules, teamNanoIdRules } =
    useCreateProjectInTeamValidationRules();

  const {
    createProjectInTeamReset,
    createProjectInTeamLoading,
    createProjectInTeamErrorMessage,
    createProjectInTeam
  } = useCreateProjectInTeam({
    query: CREATE_PROJECT_IN_TEAM_QUERY,
    cacheKeys: [ProjectCache.indexCacheKey()]
  });

  const { showToastI18nNotification, showToastNotification } =
    useToastNotification({
      appearance: 'error'
    });

  const tasksFields = useWatch<CreateProjectInTeamFormData>({
    control,
    name: CreateProjectInTeamFormFields.TASKS
  }) as CreateProjectInTeamFormDataTasks;

  const watchProjectTotal = sumBy(tasksFields, (task) =>
    sumBy(
      task.items,
      (item) => toNumber(item.quantity || 0) * toNumber(item.price || 0)
    )
  );
  const { convertCurrencyCrossExchangeUsd, convertCurrencyToUsd } =
    useConvertCurrencyRates();

  const handleCreateProjectInTeam = useCallback(
    (createInvoice?: boolean, withoutItems?: boolean) =>
      handleSubmitReactHookForm({
        dirtyFieldsOnly: false,
        onSubmit: async (_data: CreateProjectInTeamFormData) => {
          const data = { ..._data, nanoId: projectNanoId };

          if (!data.billingInfoId && createInvoice) {
            return;
          }

          const taskNames = map(data.tasks, 'name');

          if (new Set(taskNames).size !== taskNames?.length) {
            showToastI18nNotification(tasksKeys.namesHaveToBeUnique);
            return;
          }

          const splitParts = data.splitParts || [];
          const splitPartsSum = sumBy(splitParts, (part) => +part.partValue);

          if (splitPartsSum < 100) {
            each(splitParts, (part) => {
              if (+part.partValue === 0) {
                part.partValue = 100 - splitPartsSum;
                return false;
              }
            });
          }

          try {
            const response = await createProjectInTeam({
              ...(currentUser.hasPermissions(
                ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_PAYMENT_METHOD
              )
                ? data
                : // Payment method is in billing info now
                  omit(data, [CreateProjectInTeamFormFields.PAYMENT_METHOD])),
              createInvoice,
              implementationDate: data.implementationDate
                ? dateFnsConvert.toDateTimeWithTimezone(
                    currentUser.hasPermissions(
                      ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_TIME
                    )
                      ? data.implementationDate
                      : getDueDate(data.implementationDate)
                  )
                : undefined,
              tasks: map(data.tasks, (task) => {
                const smartContractTasks = isEmpty(task.users)
                  ? undefined
                  : {
                      smartContractShareInvites: map(task.users, (user) => ({
                        userId: user.id,
                        iteration: toNumber(user.iteration) || 0,
                        finalAt: !user.iteration
                          ? new Date().toISOString()
                          : undefined,
                        share: user.share,
                        status: user.status as SmartContractShareStatuses,
                        generalLedgerId: user.generalLedgerId
                      }))
                    };

                return {
                  ...task,
                  smartContractTasks,
                  items: withoutItems
                    ? undefined
                    : filter(
                        map(task.items, (item) => ({
                          ...item,
                          price: convertCurrencyToUsd(
                            item.price,
                            watchCurrency
                          ),
                          initialParams: {
                            generalLedgerId: data.generalLedgerId
                          },
                          splitPartPercents: currentUser.hasPermissions(
                            ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_FORM_SPLIT_PARTS
                          )
                            ? compact(
                                map(splitParts, (part) => +part.partValue)
                              )
                            : undefined
                        })),
                        'itemTypeId'
                      )
                };
              })
            });

            setAfterCreateProjectInTeamLoading(true);

            await afterCreateProject(
              response.createProjectInTeam?.recordNanoId
            );

            setAfterCreateProjectInTeamLoading(false);
          } catch (error) {
            if (error?.nanoId) {
              setAfterCreateProjectInTeamLoading(true);

              await afterCreateProject(projectNanoId);

              setAfterCreateProjectInTeamLoading(false);

              return;
            }

            showToastNotification(
              (error as CreateProjectInTeamError)?.fullMessages?.[0]
            );
          }
        }
      }),
    [
      afterCreateProject,
      convertCurrencyToUsd,
      createProjectInTeam,
      currentUser,
      projectNanoId,
      handleSubmitReactHookForm,
      showToastI18nNotification,
      showToastNotification,
      watchCurrency
    ]
  );

  const prevWatchCurrency = usePreviousValue(watchCurrency);

  useEffect(() => {
    if (watchCurrency !== prevWatchCurrency) {
      each(tasksFields, (task, taskIndex) => {
        each(task.items, (item, itemIndex) => {
          setValue(
            `tasks.${taskIndex}.items.${itemIndex}.price`,
            convertCurrencyCrossExchangeUsd(
              item.price,
              prevWatchCurrency,
              watchCurrency
            )
          );
        });
      });
    }
  }, [
    convertCurrencyCrossExchangeUsd,
    prevWatchCurrency,
    setValue,
    tasksFields,
    watchCurrency
  ]);

  const selectedTeamNanoId = watch(CreateProjectInTeamFormFields.TEAM_NANO_ID);

  const glInitialFilters = useMemo(
    () => ({
      companyNanoId: { eq: selectedTeamNanoId }
    }),
    [selectedTeamNanoId]
  );

  const { generalLedgers, filterGeneralLedgers } =
    useFinPaginatedGeneralLedgers<FetchGeneralLedgersQueryResponse>({
      cacheKey:
        GeneralLedgerCache.companyGeneralLedgersCacheKey(selectedTeamNanoId),
      query: FETCH_GENERAL_LEDGERS_QUERY,
      initialFilters: glInitialFilters
    });

  const prevFilters = usePreviousValue(glInitialFilters);

  useEffect(() => {
    if (prevFilters !== glInitialFilters) {
      filterGeneralLedgers(glInitialFilters);
    }
  }, [prevFilters, filterGeneralLedgers, glInitialFilters]);

  useEffect(() => {
    const defaultGl = find(
      generalLedgers,
      (item) =>
        item.generalLedgerType === GeneralLedgerGeneralLedgerTypes.DEFAULT
    );
    defaultGl &&
      setValue(CreateProjectInTeamFormFields.GENERAL_LEDGER_ID, defaultGl.id);
  }, [generalLedgers, setValue]);

  return {
    formNanoId,
    createProjectInTeamLoading:
      createProjectInTeamLoading || afterCreateProjectInTeamLoading,
    createProjectInTeamErrorMessage,
    createProjectInTeamReset,
    currentUser,
    validationErrors: {
      nameValidationError: errors?.name?.message,
      teamNanoIdValidationError: errors?.teamNanoId?.__baseBrand?.message,
      implementationDateValidationError: errors?.implementationDate?.message,
      taskItemTypeIdValidationError: (
        taskIndex: number,
        itemIndex: number
      ): string =>
        errors?.tasks?.[taskIndex]?.items?.[itemIndex]?.itemTypeId?.message,
      taskItemQtyValidationError: (
        taskIndex: number,
        itemIndex: number
      ): string =>
        errors?.tasks?.[taskIndex]?.items?.[itemIndex]?.quantity?.message,
      taskItemPriceValidationError: (
        taskIndex: number,
        itemIndex: number
      ): string =>
        errors?.tasks?.[taskIndex]?.items?.[itemIndex]?.price?.message,
      taskNameValidationError: (taskIndex: number): string =>
        errors?.tasks?.[taskIndex]?.name?.message
    },
    control,
    errors,
    resetCreateProjectInTeamForm: useCallback<
      () => Promise<unknown>
    >(async () => {
      resetFormNanoId();
      return resetForm();
    }, [resetForm, resetFormNanoId]),
    getTaskItem: useCallback<
      (
        taskIndex: number,
        itemIndex: number
      ) => CreateProjectInTeamFormDataTaskItem
    >(
      (taskIndex, itemIndex) =>
        getValues(`tasks.${taskIndex}.items.${itemIndex}`),
      [getValues]
    ),
    selectedBillingInfoId: watch(CreateProjectInTeamFormFields.BILLING_INFO_ID),
    selectedPaymentMethod: watch(CreateProjectInTeamFormFields.PAYMENT_METHOD),
    selectedGeneralLedgerId: watch(
      CreateProjectInTeamFormFields.GENERAL_LEDGER_ID
    ),
    selectedImplementationDate: watch(
      CreateProjectInTeamFormFields.IMPLEMENTATION_DATE
    ),
    selectedTeamNanoId,
    watchTaskItemIds: (taskIndex: number) =>
      tasksFields?.[taskIndex]?.items?.map((item) => item.itemTypeId),
    watchTaskItemSubtotal: (taskIndex: number, itemIndex: number): number => {
      const itemQuantity =
        tasksFields?.[taskIndex]?.items?.[itemIndex]?.quantity;

      const itemPrice = tasksFields?.[taskIndex]?.items?.[itemIndex]?.price;

      return itemQuantity * itemPrice;
    },
    watchTaskItemsCount: (taskIndex: number) =>
      tasksFields?.[taskIndex]?.items?.length || 0,
    watchProjectHasItems: some(tasksFields, (task) =>
      some(task.items, 'itemTypeId')
    ),
    watchTaskTotal: (taskIndex: number): number =>
      sumBy(
        tasksFields?.[taskIndex]?.items,
        (item) => toNumber(item.quantity || 0) * toNumber(item.price || 0)
      ),
    watchProjectTotal,
    setOwnerId: useCallback<(ownerId: UserID) => void>(
      (ownerId) =>
        setValue(CreateProjectInTeamFormFields.OWNER_ID as 'ownerId', ownerId),
      [setValue]
    ),
    setBillingInfoId: useCallback<(billingInfoId: BillingInfoID) => void>(
      (billingInfoId) =>
        setValue(
          CreateProjectInTeamFormFields.BILLING_INFO_ID as 'billingInfoId',
          billingInfoId
        ),
      [setValue]
    ),
    setNotes: useCallback<(notes: AvBillingInfoNotes) => void>(
      (notes) =>
        setValue(CreateProjectInTeamFormFields.NOTES as 'notes', notes),
      [setValue]
    ),
    setPaymentMethod: useCallback<
      (paymentMethod: InvoicePaymentMethods) => void
    >(
      (paymentMethod) =>
        setValue(
          CreateProjectInTeamFormFields.PAYMENT_METHOD as 'paymentMethod',
          paymentMethod
        ),
      [setValue]
    ),
    setTaskItemPrice: useCallback<
      (
        taskIndex: number,
        itemIndex: number,
        itemPrices: {
          price: number;
          viewPrice: number;
          viewPriceCurrency: Currencies;
        }
      ) => void
    >(
      (taskIndex, itemIndex, { price, viewPrice, viewPriceCurrency }) => {
        setValue(`tasks.${taskIndex}.items.${itemIndex}.price`, price);
        setValue(`tasks.${taskIndex}.items.${itemIndex}.viewPrice`, viewPrice);
        setValue(
          `tasks.${taskIndex}.items.${itemIndex}.viewPriceCurrency`,
          viewPriceCurrency
        );
      },
      [setValue]
    ),
    setTaskItemDescription: useCallback<
      (taskIndex: number, itemIndex: number, itemDescription: string) => void
    >(
      (taskIndex: number, itemIndex: number, itemDescription: string) => {
        setValue(
          `tasks.${taskIndex}.items.${itemIndex}.description`,
          itemDescription
        );
      },
      [setValue]
    ),
    setTerms: useCallback<(terms: TeamTerms) => void>(
      (terms) =>
        setValue(CreateProjectInTeamFormFields.TERMS as 'terms', terms),
      [setValue]
    ),
    trigger,
    watch,
    handleCreateOnlyProjectWithoutItems: useMemo<
      (e?: BaseSyntheticEvent) => Promise<void>
    >(
      () => handleCreateProjectInTeam(false, true),
      [handleCreateProjectInTeam]
    ),
    handleCreateOnlyProject: useMemo<(e?: BaseSyntheticEvent) => Promise<void>>(
      () => handleCreateProjectInTeam(),
      [handleCreateProjectInTeam]
    ),
    handleCreateProjectAndInvoice: useMemo<
      (e?: BaseSyntheticEvent) => Promise<void>
    >(() => handleCreateProjectInTeam(true), [handleCreateProjectInTeam]),
    register,
    registerName: register(CreateProjectInTeamFormFields.NAME, nameRules),
    registerTeamNanoId: register(
      CreateProjectInTeamFormFields.TEAM_NANO_ID,
      teamNanoIdRules
    ),
    registerImplementationDate: register(
      CreateProjectInTeamFormFields.IMPLEMENTATION_DATE,
      implementationDateRules
    ),
    registerDescription: register(CreateProjectInTeamFormFields.DESCRIPTION),
    registerGeneralLedgerId: register(
      CreateProjectInTeamFormFields.GENERAL_LEDGER_ID,
      currentUser.hasPermissions(
        ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_GENERAL_LEDGER_ID
      )
        ? {
            required: formsErrors.required
          }
        : undefined
    ),
    companyCurrency: companyCurrency.currency,
    currencyExchangeRate,
    currencyPrefix,
    handleChangeCurrency: useCallback<MultiSelectFieldChangeCallbackType>(
      (option: MultiSelectDataType) => {
        setCompanyCurrency({ currency: option.value as Currencies });
      },
      [setCompanyCurrency]
    ),
    setCurrency: useCallback(
      (value: Currencies) => {
        setValue(CreateProjectInTeamFormFields.PREFERRED_CURRENCY, value);
      },
      [setValue]
    ),
    watchCurrency,
    watchSplitParts: () => getValues(CreateTaskItemsFormFields.SPLIT_PARTS),
    setFocusSplitPart: (index: number) => {
      setFocus(`${CreateTaskItemsFormFields.SPLIT_PARTS}.${index}.partValue`);
    },
    setSplitPartValue: useCallback<
      (partIndex: number, partValue: number) => void
    >(
      (partIndex, partValue) =>
        setValue(`splitParts.${partIndex}.partValue`, partValue),
      [setValue]
    )
  };
}

export default useCreateProjectInTeamForm;
